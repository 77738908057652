/* Estilos para o carrossel de fotos */
.carousel .slide img {
    border-radius: 10px;
    max-height: 500px;
    object-fit: cover;
  }
  
  .carousel .legend {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
  }
  