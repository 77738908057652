/* Estilos para a tabela do cronograma */
.cronograma-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.time-column{
    width: 20%;
}

.cronograma-table th,
.cronograma-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 1rem;
    /* Tamanho base da fonte */
    text-align: center;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
}

.cronograma-table tr {
    border-bottom: #333 solid 1px;
    border-top: #333 solid 1px;
    border-left: none;
    border-right: none;
}

.cronograma-table th {
    background-color: #333;
    color: white;
    font-weight: bold;
}

.cronograma-table tr:nth-child(even) td {
    background-color: #f9d1d1;
}

.cronograma-table tr:nth-child(odd) td {
    background-color: #fff;
}

.cronograma-day {
    margin-top: 20px;
    width: 100%;
}

.cronograma-day h3 {
    margin-bottom: 10px;
}

/* Estilos responsivos */
@media (max-width: 768px) {

    .cronograma-table th,
    .cronograma-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-size: 0.6rem;
        /* Diminui a fonte para telas menores */
        border-bottom: none;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .cronograma-table tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: #333 solid 1px;
        border-top: #333 solid 1px;
        border-left: none;
        border-right: none;
    }

    .cronograma-table tr:before {
        content: attr(data-day);
        display: block;
        background: #333;
        color: white;
        padding: 5px;
        font-weight: bold;
    }

    .speaker-photo {
        max-width: 80px;
        /* Ajusta o tamanho da foto para telas menores */
    }
}

@media (max-width: 480px) {

    .cronograma-table th,
    .cronograma-table td {
        font-size: 0.5rem;
        /* Diminui ainda mais a fonte para telas muito pequenas */
    }

    .speaker-photo {
        max-width: 60px;
        /* Ajusta o tamanho da foto para telas muito pequenas */
    }
}